import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactAnimation from "@micado-digital/react-animation/ReactAnimation";
import Container from "../Container";
import EnquiryButton from "../Header/EnquiryButton";

import styles from "./Animation.styles";

const Animation = () => {
	const { REACT_APP_PATH } = process.env;
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { animationID } = pageData;
	var animationMediaFormats  = {
		xs: "animation-mobile",
		sm: "animation"
	}

	if ( pageData.layoutVariant === "intro" ){
		animationMediaFormats  = {
			xs: "animation-intro-mobile",
			sm: "animation-intro"
		}
	}

	return (
		<div className={clsx(css.animation, "mco-view-component-animation")}>
			<Container
				className={clsx(css.container, "mco-view-component-animation__container")}
			>
				<EnquiryButton />
				<ReactAnimation
					api={`${REACT_APP_PATH}/animationV3.json.api`}
					id={animationID}
					showBullets={true}
					showArrows={true}
					mediaFormats={animationMediaFormats}
					webpFallback
				/>
			</Container>
		</div>
	);
};

export default Animation;
