import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import ReactLink from "@micado-digital/react-link/ReactLink";
import UiContext from "@UiContext";
import Hamburger from "./Hamburger";
import DesktopMenu from "./Desktopmenu";
import Language from "./Language";
import EnquiryButton from "./EnquiryButton";
import OpeningTimesButton from "./OpeningTimesButton";
import WebProfileContext from "@WebProfileContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import PageContext from "@PageContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Header.styles";

const Header = () => {
	const [ui] = useContext(UiContext);
	const [profileData] = useContext(WebProfileContext);
	const [menuOpen, setMenuOpen] = useState(false);
	const [pageData] = useContext(PageContext);
	const { menuGroups } = pageData;
	const menuGroup = menuGroups?.find(menuGroup => menuGroup?.title === "Hauptmenü");
	const css = styles();
	const { _loading } = ui;
	const screenMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const l = useLocals({
		lang: pageData.lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	useEffect(() => {
		if (!_loading) {
			setMenuOpen(false);
		}
	}, [_loading]);

	if (!menuOpen) {
		document.body.classList.add("menu-closed");
		document.body.classList.remove("menu-open");
	} else {
		document.body.classList.remove("menu-closed");
		setTimeout(function () {
			document.body.classList.add("menu-open");
		}, 100);
	}

	const Menu = () => {
		if (screenMD) {
			return (
				<DesktopMenu menuGroup={menuGroup} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			);
		} else {
			return (
				<DesktopMenu menuGroup={menuGroup} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			);
		}
	};

	return (
		<header className={clsx(css.header, "mco-view-component-header")}>
			<ReactLink to="/" className={css.logoWrapper}>
				<img
					className={clsx(css.logo, "mco-view-component-header__logo")}
					src="/img/dr-helmuth-obermoser.svg"
					alt="Dr. Helmuth Obermoser"
				/>
			</ReactLink>
			<div className={css.links}>
				<Language />
				<a className={css.phone} href={"tel:" + profileData.phone}>
					<img src="/img/icons/phone.svg" alt="Phone" />
					<p>{profileData.phone}</p>
				</a>
				<EnquiryButton />
				<OpeningTimesButton />
				<div
					className={css.desktopMenuTrigger}
					menuOpen={menuOpen}
					onClick={() => setMenuOpen(!menuOpen)}
				>
					<p>
						<Local identifier="menu.open" l={l} />
					</p>
					<img src="/img/icons/burger-desktop.svg" alt="Phone" />
				</div>
			</div>
			<Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			{menuOpen && Menu()}
		</header>
	);
};

export default Header;
