import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	hamburger: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		height: theme.spacing(6),
		justifyContent: "center",
		marginLeft: "auto",
		width: theme.spacing(6),
		[theme.breakpoints.up("lg")]: {
			display: "none"
		}
	},
	inner: {
		display: "flex",
		height: 24,
		flexDirection: "column",
		justifyContent: "space-between",
		position: "relative",
		width: 28
	},
	bar: {
		background: "#258D9D",
		height: 3
	}
}));

export default styles;
