import loadable from "@loadable/component";
import Container from "../../helper/container";

const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const Form = loadable(() => import("@micado-digital/react-form"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactReferences01 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences01")
);
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactSingleImage01 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage01")
);
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactImageText01 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText01")
);
const ReactGallery01 = loadable(() => import("@micado-digital/react-gallery/ReactGallery01"));
const ReactTeaserList01 = loadable(() =>
	import("@micado-digital/react-teaser-list/ReactTeaserList01")
);
const ReactTeaser02 = loadable(() => import("@micado-digital/react-teaser/ReactTeaser02"));
const ReactSingleTeaser01 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser01")
);
const HeadlineTextCombi = loadable(() => import("../HeadlineTextCombi"));
const Doctor = loadable(() => import("../Doctor"));
const DoctorCharacteristics = loadable(() => import("../DoctorCharacteristics"));
const Themeteaser = loadable(() => import("../Themeteaser"));
const CustomTeasergroup = loadable(() => import("../CustomTeasergroup"));

const getJSXElement = (element, l) => {
	const { REACT_APP_PATH } = process.env;
	const { children, elements, id, media, tag, text, title } = element;

	const teaserButton = l("elements.teaser.button");

	switch (tag) {
		case "basic-headline": {
			return (
				<Container
					key={element?.id}
					maxWidth="md"
					name="headline"
					variant={(element?.variant || 0) + 1}
				>
					<ReactHeadline01 text={element?.text} variant={element.variant} />
				</Container>
			);
		}

		case "basic-text": {
			return (
				<Container key={element?.id} maxWidth="md" name="text" variant={element?.variant}>
					<ReactText01 text={element?.text} />
				</Container>
			);
		}

		case "basic-video": {
			return (
				<Container key={id} tag={tag}>
					<ReactVideo02
						media={media[0]}
						mediaFormats={{ xs: "video-mobile", sm: "video" }}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			return <ReactHTML key={id} html={text} />;
		}

		case "basic-copyrights": {
			return (
				<Container key={id} tag={tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-dropdown-group": {
			var dropdownname = "default";

			if (element?.variant === 1) {
				dropdownname = "doctor";
			}

			return (
				<Container key={id} tag={tag} name={dropdownname}>
					<ReactDropDown01 option={0} title={element?.children?.[0]?.title}>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-form": {
			return (
				<Container key={id} tag={tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST" key={id}>
						<Connector formElements={elements} formID={id} />
					</Form>
				</Container>
			);
		}

		case "basic-list": {
			return (
				<Container
					key={element?.id}
					name={element?.variant === 1 ? "list__bg" : "list"}
					maxWidth={element?.variant === 1 ? "lg" : "md"}
				>
					<ReactList01
						headlineVariant="h2"
						icon={<ReactSVGIcon color="primary" src="/img/icons/check.svg" size={30} />}
						spacing={2}
						textcontent={element?.textcontent}
						title={element?.title}
						variant={element?.variant}
					/>
				</Container>
			);
		}

		case "basic-links": {
			return (
				<Container key={element?.id} maxWidth="md" name="links">
					<ReactReferences01
						elements={element?.elements}
						title={element?.title}
						startIcon={
							<ReactSVGIcon color="paper" src="/img/icons/arrow-right.svg" size={16} />
						}
						hasIcon={false}
						headlineVariant="h2"
					/>
				</Container>
			);
		}

		case "basic-table": {
			const newTableArray = [];

			for (const entries of element?.textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});
				newTableArray.push(columns);
			}
			return (
				<Container
					key={element?.id}
					maxWidth={element?.variant === 1 ? "lg" : "md"}
					name="table"
				>
					<ReactTable01
						addition={element?.addition}
						headlineVariant="h2"
						items={newTableArray}
						title={element?.title}
						sticky={false}
					/>
				</Container>
			);
		}

		case "basic-download": {
			return (
				<Container key={element?.id} maxWidth="md" name="download">
					<ReactFile01 media={element?.media} title={element?.title} />
				</Container>
			);
		}

		case "basic-imagetext": {
			return (
				<Container key={element?.id} name="imagetext" variant={element?.variant}>
					<Container maxWidth="lg">
						<ReactImageText01
							addition2={element?.addition2}
							alt={element?.addition}
							headlineVariant="h2"
							option={element?.option}
							media={element?.media ? element?.media?.[0] : {}}
							mediaFormats={{
								xs: "basic-imagetext-mobile",
								sm: "basic-imagetext"
							}}
							mediaPath={REACT_APP_PATH}
							title={element?.title}
							text={element?.text}
						/>
					</Container>
				</Container>
			);
		}

		case "basic-singleimage": {
			return (
				<Container key={element?.id} maxWidth="xl" name="singleimage">
					<ReactSingleImage01
						addition={element?.addition}
						option2={element?.option2}
						media={element?.media ? element?.media?.[0] : {}}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-singleimage-mobile",
							sm: "basic-singleimage"
						}}
						reference={element?.reference}
						title={element?.title}
						text={element?.text}
					></ReactSingleImage01>
				</Container>
			);
		}

		case "basic-gallery": {
			return (
				<Container key={element?.id} maxWidth="md" name="gallery">
					<ReactGallery01 elements={element?.elements} />
				</Container>
			);
		}

		case "basic-singleteaser": {
			var teasername = "singleteaser-image-left",
				mediaFormat = {
					xs: "teaser-subformat"
				};

			if (element?.variant === 1) {
				teasername = "singleteaser-image-right";
			}
			if (element?.variant === 2) {
				teasername = "singleteaser-image-fullwidth";
				mediaFormat = {
					xs: "teaser-teasergroup",
					sm: "basic-teaser"
				};
			}
			if (element?.variant === 3) {
				teasername = "singleteaser-theme";
				mediaFormat = {
					xs: "teaser-teasergroup",
					sm: "basic-teaser"
				};
			}
			if (element?.variant === 4) {
				teasername = "singleteaser-image-left-bg";
			}

			return (
				<Container key={element?.id} maxWidth="lg" name={teasername}>
					<ReactSingleTeaser01
						buttonLabel={teaserButton}
						buttonVariant="text"
						headlineVariant="h2"
						teaser={element?.teaser}
						media={element?.teaser?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={mediaFormat}
						variant={element?.variant}
					></ReactSingleTeaser01>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			const tmpTeaserObj = {
				blogDate: "",
				link: element.textcontent.items.find(item => item.name === "URL").text,
				media: element.elements[0].media,
				text: element.textcontent.items.find(item => item.name === "Text").text,
				title: element.textcontent.items.find(item => item.name === "Title").text
			};

			return (
				<Container key={element?.id} maxWidth="lg" name="singleteaser">
					<ReactSingleTeaser01
						buttonLabel={teaserButton}
						buttonVariant="text"
						headlineVariant="h2"
						teaser={tmpTeaserObj}
						media={tmpTeaserObj.media?.[0]}
						mediaPath={REACT_APP_PATH}
						target="_blank"
						mediaFormats={{
							xs: "teaser-teasergroup",
							sm: "basic-teaser"
						}}
						variant={element?.variant}
					></ReactSingleTeaser01>
				</Container>
			);
		}

		case "basic-teaser": {
			if (element?.variant === 1) {
				const breakpoints = {
					0: {
						slidesPerView: 1.2,
						spaceBetween: 20
					},
					500: {
						slidesPerView: 1.5,
						spaceBetween: 20
					},
					980: {
						slidesPerView: 2.2,
						spaceBetween: 30
					},
					1200: {
						slidesPerView: 2.5,
						spaceBetween: 40
					}
				};

				return (
					<Container key={element?.id} maxWidth="lg" name="teaser-slider">
						<ReactTeaserList01
							button={{
								label: "Projekte entdecken",
								link: element?.reference?.pageName,
								position: "top"
							}}
							headline={element?.title}
							headlineVariant="h2"
							loop={false}
							sliderBreakpoints={breakpoints}
							showPagination={false}
							speed={1000}
							spacing={4}
							text={element?.text}
							variant="slider"
						>
							{element?.teasergroup?.items?.map(
								({ id, link, media, title, text, linkTarget }) => {
									return (
										<ReactTeaser02
											key={id}
											link={link}
											media={media ? media?.[0] : {}}
											mediaFormats={{
												xs: "basic-teaser"
											}}
											mediaPath={REACT_APP_PATH}
											target={linkTarget}
											text={text}
											title={title}
										/>
									);
								}
							)}
						</ReactTeaserList01>
					</Container>
				);
			}
			return (
				<Container key={element?.id} maxWidth="lg" name="teaser-grid">
					<ReactTeaserList01
						gridBreakpoints={{
							xs: 12,
							sm: 6,
							md: 4,
							lg: 4,
							xl: 4
						}}
						headline={element?.title}
						headlineVariant="h2"
						spacing={4}
					>
						{element?.teasergroup?.items?.map(
							({ id, link, media, title, text, linkTarget }) => {
								return (
									<ReactTeaser02
										key={id}
										link={link}
										media={media ? media?.[0] : {}}
										mediaFormats={{
											xs: "teaser-teasergroup"
										}}
										mediaPath={REACT_APP_PATH}
										target={linkTarget}
										text={text}
										title={title}
									/>
								);
							}
						)}
					</ReactTeaserList01>
				</Container>
			);
		}

		case "headline-text-combination": {
			return (
				<Container key={element?.id} maxWidth="md" name="headline-text-combination">
					<HeadlineTextCombi elements={element} />
				</Container>
			);
		}

		case "doctor": {
			return (
				<Container key={element?.id} maxWidth="md" name="doctor">
					<Doctor elements={element} />
				</Container>
			);
		}

		case "doctorCharacteristics": {
			return (
				<Container key={element?.id} maxWidth="md" name="doctorCharacteristics">
					<DoctorCharacteristics elements={element} />
				</Container>
			);
		}

		case "themeteaser": {
			return (
				<Container key={element?.id} maxWidth="md" name="themeteaser">
					<Themeteaser elements={element} />
				</Container>
			);
		}

		case "custom-teasergroup": {
			return (
				<Container key={element?.id} maxWidth="md" name="customTeasergroup">
					<CustomTeasergroup elements={element} />
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
