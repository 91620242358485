import React, { useContext } from "react";
import styles from "./OpeningTimesButton.styles";
import clsx from "clsx";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import PageContext from "@PageContext";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

const Language = () => {
	const css = styles();
	const sysLink = useSystemLinks();
	const [pageData] = useContext(PageContext);
	const l = useLocals({
		lang: pageData.lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	return (
		<a className={clsx(css.button, "mco-view-component-secondary-button")} href={sysLink("buttonOpeningTimes")}><Local identifier="header.buttonOpeningTimes" l={l} /></a>
	);
};

export default Language;
