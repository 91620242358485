import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	lang: {
		color: "#FFFFFF",
		cursor: "pointer",
		fontFamily: "Dosis",
		fontWeight: "700",
		textTransform: "uppercase",
		letterSpacing: 2,
		position: "relative",
		marginRight: 52,
		"&:after": {
			background: "#FFFFFF",
			content: '""',
			height: 21,
			width: 1,
			position: "absolute",
			right: "-26px",
			top: 0
		}
	}
}));

export default styles;
