import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		"& a": {
			color: "#FFFFFF",
			fontFamily: "Dosis",
			opacity: 1,
			fontSize: 32,
			lineHeight: "35px",
			textTransform: "none",
			fontWeight: 400,
			display: "block",
			textAlign: "right",
			letterSpacing: 0
		},
		[theme.breakpoints.up("lg")]: {
			"& a": {
				fontSize: 42,
				fontWeight: 300,
				letterSpacing: "1px"
			}
		}
	},
	hideOnDesktop: {
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	}
}));

export default styles;
