import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	MenuList: {
		"& li + li": {
			marginTop: 4
		}
	}
}));

export default styles;
