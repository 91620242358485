import React from "react";
import clsx from "clsx";
import FooterMenuItem from "../FooterMenuItem";

import styles from "./FooterMenuList.styles";

const FooterMenuList = ({ items, selectedMenuItemID }) => {
	const css = styles();

	return (
		<ul className={clsx(css.MenuList, "mco-view-component-footermenu__menulist")}>
			{items?.map(item => {
				const {id} = item

				return (
					<FooterMenuItem
						item={item}
						key={id}
						selected={id === selectedMenuItemID}
					/>
				)
			})}
		</ul>
	);
};

export default FooterMenuList;
