import makeStyles from "@material-ui/styles/makeStyles";

const styles = (theme) => {
	return makeStyles(theme => ({
		item: {

		},
		link: {
			color: "#FFFFFF",
			display: "block",
			fontWeight: 400,
			fontSize: 12,
			opacity: 0.8,
			lineHeight: "16px",
			"&:hover": {
				opacity: 1
			},
			[theme.breakpoints.up("lg")]: {
				marginTop: 0,
				marginLeft: 16
			}
		},
		selected: {
			opacity: 1
		}
	}))();
};

export default styles;
