import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import UIContainer from "@material-ui/core/Container";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
	margin: {
		marginTop: theme?.spacing(6),
		[theme.breakpoints.up("md")]: {
			marginTop: theme?.spacing(8)
		}
	}
}));

const Container = props => {
	const { margin = true, maxWidth = false, name, variant } = props;
	const css = useStyles();

	return (
		<UIContainer
			className={clsx(
				"mco-container",
				{
					"mco-container-margin": margin
				},
				{
					[css.margin]: margin
				},
				"mco-container-" + maxWidth,
				{
					["mco-container-" + name]: name
				},
				{
					["mco-container-" + name + "-" + variant]: variant
				}
			)}
			maxWidth={maxWidth}
		>
			{props.children}
		</UIContainer>
	);
};

Container.propTypes = {
	margin: PropTypes.bool,
	maxWidth: PropTypes.string,
	name: PropTypes.string,
	variant: PropTypes.number
};

export default Container;