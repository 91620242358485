import React from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import MenuItem from "../MenuItem";
import { animationContainer2, animationItem2 } from "../utils/animationLevel2";

import styles from "./Level2.styles";

const Level2 = ({ items, selectedMenuLevel1ID }) => {
	const css = styles();

	if (!items || items.length === 0) return null;

	return (
		<motion.ul
			animate="animate"
			className={clsx(css.level2, "mco-view-component-desktopmenu__level2")}
			initial="initial"
			key={selectedMenuLevel1ID}
			variants={animationContainer2}
		>
			{items.map(subitem => (
				<motion.li key={subitem.id} variants={animationItem2}>
					<MenuItem item={subitem} level={2} selected={subitem.selected} />
				</motion.li>
			))}
		</motion.ul>
	);
};

export default Level2;
