import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	animation: {
		height: "55vh",
		maxHeight: 316,
		marginTop: 90,
		[theme.breakpoints.up("lg")]: {
			maxHeight: "none",
			marginTop: 120
		},
		"& .mco-view-component-button": {
			paddingLeft: 24,
			paddingRight: 24,
			position: "absolute",
			right: 24,
			top: 110,
			width: "auto",
			zIndex: 2,
			[theme.breakpoints.up("lg")]: {
				display: "none"
			}
		}
	},
	container: {
		maxWidth: "none",
		height: "100%",
		position: "relative",
		padding: 0
	}
}));

export default styles;
