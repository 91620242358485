import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import groupFollowingElementsByTag from "@micado-digital/react-ui/utils/groupFollowingElementsByTag";
import getJSXElement from "./getJSXElement";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Elements.styles";

const Elements = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	let { elements, lang } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	// group dropdowns

	elements = groupFollowingElementsByTag(
		elements,
		"basic-dropdown-group",
		["basic-dropdown", "*"],
		"basic-dropdown",
		"basic-dropdown"
	);

	return (
		<div className={clsx(css.elements, "mco-elements")}>
			{elements?.map(element => {
				return getJSXElement(element, l);
			})}
		</div>
	);
};

export default Elements;
