import React, { useContext } from "react";
import clsx from "clsx";
import Header from "../../components/views/Header";
import Footer from "../../components/views/Footer";
import Animation from "../../components/views/Animation";
import Elements from "../../components/Elements";
import PageContext from "@PageContext";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";

import styles from "./Default.styles";

const Default = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const css = styles();

	return (
		<div className={clsx(css.root, "mco-view-root", pageData.layoutVariant)}>
			<Header />
			<div className={clsx(css.default, "mco-view-default")}>
				{pageData.layoutVariant !== "no-animation" && <Animation />}
				<div>
					<Elements />
				</div>
				<PageTransition color="rgba(37, 141, 157, 1)" position="top" />
				<Footer />
				<micado-ial language={lang} path={REACT_APP_PATH}></micado-ial>
			</div>
		</div>
	);
};

export default Default;
