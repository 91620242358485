import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	button: {
		background: "#FFFFFF",
		borderRadius: 24,
		color: "#258D9D",
		display: "block",
		lineHeight: "48px",
		textAlign: "center",
		textTransform: "uppercase",
		letterSpacing: 2,
		fontFamily: "Dosis",
		fontWeight: "700",
		width: "100%",
		boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.12)"
	}
}));

export default styles;
