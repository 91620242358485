import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import FooterMenuList from "./FooterMenuList";

import styles from "./Footermenu.styles";

const Footermenu = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { menuGroups } = pageData;
	const [selectedMenuListID, setSelectedMenuListID] = useState();
	const footerMenuGroup = menuGroups?.find(menuGroup => menuGroup?.title === "Footermenü");

	useEffect(() => {
		const selectedMainPoint = footerMenuGroup?.items?.find(item => item.selected);
		setSelectedMenuListID(selectedMainPoint?.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={clsx(css.footermenu, "mco-view-component-footermenu")}>
			<FooterMenuList
				items={footerMenuGroup?.items}
				selectedMenuListID={selectedMenuListID}
			/>
		</div>
	);
};

export default Footermenu;
