import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	menuHeader: {
		color: "#FFFFFF",
		right: "100% !important",
		transition: "right .3s ease",
		position: "fixed",
		top: 0,
		background: "#1a646e",
		width: "100%",
		zIndex: 1
	},
	container: {
		alignItems: "center",
		display: "flex",
		height: 90,
		maxWidth: "none",
		[theme.breakpoints.up("lg")]: {
			height: 120,
			paddingLeft: 60,
			paddingRight: 51
		}
	},
	closeLabel: {
		cursor: "pointer",
		fontFamily: "Dosis",
		fontWeight: 700,
		fontSize: 14,
		letterSpacing: 0,
		textTransform: "uppercase",
		marginLeft: "auto",
		[theme.breakpoints.up("lg")]: {
			fontSize: 16
		}
	},
	close: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		height: theme.spacing(6),
		justifyContent: "center",
		marginLeft: "6px",
		width: theme.spacing(6),
		[theme.breakpoints.up("lg")]: {
			marginLeft: "15px"
		}
	},
	inner: {
		cursor: "pointer",
		height: 19,
		position: "relative",
		width: 32
	},
	bar: {
		background: "#FFFFFF",
		height: 3,
		marginTop: 7,
		position: "absolute",
		width: "100%"
	},
	logoWrapper: {
		display: "none",
		[theme.breakpoints.up("lg")]: {
			display: "block"
		},
		"& img": {
			display: "block",
			width: 360
		}
	}
}));

export default styles;
