import React, { useContext } from "react";
import styles from "./Contact.styles";
import clsx from "clsx";
import Language from "../../Language";
import EnquiryButton from "../../EnquiryButton";
import OpeningTimesButton from "../../OpeningTimesButton";
import WebProfileContext from "@WebProfileContext";

const Contact = ({...props}) => {
	const css = styles();
	const [profileData] = useContext(WebProfileContext);
	const { links } = profileData;

	return (
		<div className={clsx(css.contact, "mco-view-component-contact")}>
			<div className={clsx(css.row, "mco-view-component-contact__links")}>
				<Language />
				<div className={css.links}>
					{links?.map((item, counter) => {
						return (
							<a className={css.icon} href={item.url} target="_blank" rel="noopener noreferrer">
								<img src={"/img/icons/" + item.type + ".svg"} alt={item.title} />
							</a> 
						)
					})}
					<a className={css.icon} href={"mailto:" + profileData.mail }>
						<img
							src="/img/icons/mail.svg"
							alt="Mail"
						/>
					</a>
					<a className={css.icon} href={"tel:" + profileData.phone }>
						<img
							src="/img/icons/phone-white.svg"
							alt="Phone"
						/>
						<span>{profileData.phone}</span>
					</a>
				</div>
			</div>
			<div className={clsx(css.row, "mco-view-component-contact__buttons")}>
				<OpeningTimesButton />
				<EnquiryButton />
			</div>
		</div>
		/*
		<div className={css.contact}>
			<span>{props.title}</span>
			<a href={"tel:" + props.phoneLink}>{props.phoneLabel}</a>
		</div>
		*/
	);
};

export default Contact;
