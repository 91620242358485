import clsx from "clsx";
import React, { useContext } from "react";
import WebProfileContext from "@WebProfileContext";
import Metamenu from "./Metamenu";
import Footermenu from "./Footermenu";
import ReactLink from "@micado-digital/react-link/ReactLink";
import styles from "./Footer.styles";
import PageContext from "@PageContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

const Footer = () => {
	const css = styles();
	const [profileData] = useContext(WebProfileContext);
	const { links } = profileData;

	const [pageData] = useContext(PageContext);
	const l = useLocals({
		lang: pageData.lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	return (
		<footer className={clsx(css.footer, "mco-view-component-footer")}>
			<div className={css.top}>
				<div className={"topLeftWrapper"}>
					<ReactLink to="/" className={"logoWrapper"}>
						<img
							className={"logo"}
							src="/img/dr-helmuth-obermoser-light.svg"
							alt="Dr. Helmuth Obermoser"
						/>
					</ReactLink>
					<div className={"left"}>
						<p className={"address"}>
							<strong>{profileData.name}</strong>
							{profileData.street}
							<br />
							{profileData.zip} {profileData.city}
							<br />
							<br />
							<br />
							{profileData.addition}
						</p>
						<a className={"contact"} href={"tel:" + profileData.phone}>
							{profileData.phone}
						</a>
						<a className={"contact"} href={"mailto:" + profileData.mail}>
							{profileData.mail}
						</a>
						<div className={"social"}>
							{links?.map((item, counter) => {
								return (
									<a href={item.url} target="_blank" rel="noopener noreferrer" key={counter}>
										<img src={"/img/icons/" + item.type + ".svg"} alt={item.title} />
									</a>
								);
							})}
							<a href={"mailto:" + profileData.mail}>
								<img src="/img/icons/mail.svg" alt="Mail" />
							</a>
						</div>
					</div>
					<div className={"right"}>
						<p>Über uns</p>
						<Footermenu />
					</div>
				</div>
				<div className={"topRightWrapper"}>
					<img className={"footermap"} src="/img/footer-map.svg" alt="Footer - Karte" />
				</div>
			</div>
			<div className={css.bottom}>
				<div className={"bottomLeftWrapper"}>
					<ReactLink to="https://www.micado.cc" className={css.micadoWrapper}>
						<img className={"micado"} src="/img/micado.svg" alt="created by micado" />
					</ReactLink>
				</div>
				<div className={"bottomRightWrapper"}>
					<p>
						<Local identifier="footer.copyright" l={l} />
					</p>
					<Metamenu />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
