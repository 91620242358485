export const theme = {
	palette: {
		header: {
			background: "#F8F7F7",
			menu: "#dddddd"
		},
		primary: {
			main: "#258D9D"
		},
		text: {
			primary: "#666666"
		}
	},
	typography: {
		fontFamily: "'Open Sans', sans-serif",
		fontSize: 16
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				":root": {
					"--mco-palette-primary-main": "#000000"
				},
				a: {
					color: "#258D9D"
				}
			}
		}
	}
};
