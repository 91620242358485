import styles from "./Language.styles";
import React, { Suspense, useContext, useState } from "react";
import PageContext from "@PageContext";
import clsx from "clsx";
import ReactLangSelection from "@micado-digital/react-lang-selection/ReactLangSelection";
import WebProfileContext from "@WebProfileContext";

const Language = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const [showLang, setShowLang] = useState(false);
	const [profileData] = useContext(WebProfileContext);
	const { lang, meta: { links } = {} } = pageData;
	const { languages } = profileData;

	return (
		<>
			<div className={clsx(css.lang, "mco-view-component-language")} onClick={() => setShowLang(true)}>{pageData.lang}</div>
			<Suspense fallback={<></>}>
				{showLang && (
					<ReactLangSelection
						languages={languages}
						currentLanguage={lang}
						links={links}
						setOverlay={setShowLang}
					/>
				)}
			</Suspense>
		</>
	);
};

export default Language;
