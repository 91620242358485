import React from "react";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import ReactLink from "@micado-digital/react-link/ReactLink";

import styles from "./FooterMenuItem.styles";

const FooterMenuItem = ({ item, selected }) => {
	const theme = useTheme();
	const css = styles(theme);

	return (
		<li className={clsx(css.item, "mco-view-component-footermenu__item")}>
			<ReactLink
				className={clsx(css.link, "mco-view-component-footermenu__link", {
					[css.selected]: selected,
					"mco-view-component-footermenu__item--selected": selected
				})}
				to={item.link}
			>{item.title}
			</ReactLink>
		</li>
	);
};

export default FooterMenuItem;
