import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level2: {
		padding: "0 27px",
		position: "relative",
		marginTop: 31,
		marginBottom: 50,
		"&:after": {
			background: "#258D9D",
			content: "''",
			height: "100%",
			position: "absolute",
			right: 0,
			top: 0,
			width: 1
		},
		"& a": {
			color: "#FFFFFF",
			fontFamily: "Dosis",
			opacity: 1,
			fontSize: 28,
			lineHeight: "36px",
			textTransform: "none",
			fontWeight: 400,
			display: "block",
			textAlign: "right",
			letterSpacing: 0
		},
		[theme.breakpoints.up("lg")]: {
			marginTop: 0,
			marginBottom: 0,
			"&:after": {
				left: 0,
				right: "auto"
			},
			"& a": {
				fontSize: 32,
				fontWeight: 300,
				whiteSpace: "nowrap"
			}
		}
	}
}));

export default styles;
