import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	contact: {
		background: "#1a646f",
		bottom: 0,
		right: "100% !important",
		transition: "right .3s ease",
		paddingBottom: 40,
		position: "fixed",
		width: "100%",
		zIndex: 1,
		"& div:not(.mco-view-component-language) + div": {
			marginTop: 32
		},
		"& .mco-view-component-contact__buttons": {
			maxWidth: "500px !important",
			paddingLeft: 16,
			paddingRight: 16
		},
		"&:after": {
			background: "linear-gradient(to bottom, rgba(26,100,111,0) 0%,rgba(26,100,111,1) 100%)",
			content: '""',
			height: "100px",
			width: "100%",
			position: "absolute",
			right: "0",
			bottom: "100%",
			zIndex: "-1"
		},
		"& .mco-view-component-button": {
			fontSize: 12,
			marginLeft: 8
		},
		"& .mco-view-component-secondary-button": {
			fontSize: 12
		},
		"@media (max-width: 360px)": {
			"& .mco-view-component-button": {
				fontSize: 10
			},
			"& .mco-view-component-secondary-button": {
				fontSize: 10
			}
		},
		[theme.breakpoints.up("lg")]: {
			paddingBottom: 72,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			"& .mco-view-component-language": {
				color: "#FFFFFF !important"
			},
			"& div:not(.mco-view-component-language) + div": {
				marginTop: 0,
				marginRight: 60
			},
			"& .mco-view-component-contact__links": {
				marginLeft: "auto",
				marginRight: "auto"
			},
			"& .mco-view-component-button": {
				whiteSpace: "nowrap",
				margin: "0 !important",
				background: "#FFFFFF !important",
				color: "#258D9D !important",
				paddingLeft: "24px !important",
				paddingRight: "24px !important",
				transition: "opacity 300ms ease !important"
			},
			"& .mco-view-component-button:hover": {
				opacity: 0.8
			}
		}
	},
	row: {
		display: "flex",
		maxWidth: 240,
		margin: "0 auto",
		justifyContent: "space-between",
		alignItems: "center",
		[theme.breakpoints.up("lg")]: {
			marginLeft: 0,
			marginRight: 0
		}
	},
	links: {
		display: "flex",
		flex: 1,
		justifyContent: "space-between",
		[theme.breakpoints.up("lg")]: {
			"& a + a": {
				marginLeft: 27
			}
		}
	},
	icon: {
		display: "flex",
		alignItems: "center",
		"& img": {
			display: "block",
			width: 32
		},
		"& span": {
			display: "none"
		},
		[theme.breakpoints.up("lg")]: {
			"& span": {
				display: "block",
				color: "#FFFFFF",
				whiteSpace: "nowrap",
				fontWeight: 700,
				fontFamily: "Dosis",
				fontSize: 14,
				letterSpacing: 2,
				marginLeft: 13
			}
		}
	}
}));

export default styles;
