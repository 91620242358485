import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	footer: {
		background: "#103B42",
		color: "rgba(255, 255, 255, 0.8)",
		fontFamily: "Dosis",
		fontSize: 16,
		padding: "32px 24px",
		[theme.breakpoints.up("lg")]: {
			padding: "77px 60px 64px"
		}
	},

	/* Top */

	top: {
		"& .topLeftWrapper": {
			"& .logoWrapper": {
				display: "block",
				marginBottom: 56,
				width: "100%",
				"& .logo": {
					display: "block",
					maxWidth: "100%"
				},
				[theme.breakpoints.up("lg")]: {
					marginBottom: 71
				}
			},
			"& .left": {
				"& .address": {
					marginBottom: 16,
					"& strong": {
						color: "#FFFFFF",
						display: "block",
						marginBottom: 4
					}
				},
				"& .contact": {
					color: "#FFFFFF",
					display: "block",
					fontSize: 20,
					lineHeight: "26px",
					letterSpacing: "1px"
				},
				"& .social": {
					display: "flex",
					marginTop: 16
				},
				"& .social a": {
					display: "block"
				},
				"& .social a + a": {
					marginLeft: 14
				},
				"& .social a img": {
					display: "block",
					width: 32
				},
				[theme.breakpoints.up("lg")]: {
					width: "50%"
				}
			},
			"& .right": {
				marginTop: 56,
				"& p": {
					color: "#FFFFFF",
					fontWeight: 600,
					lineHeight: "26px",
					marginBottom: 4
				},
				[theme.breakpoints.up("lg")]: {
					marginTop: 0,
					width: "50%"
				}
			},
			[theme.breakpoints.up("lg")]: {
				display: "flex",
				flexWrap: "wrap",
				flex: 1,
				alignContent: "flex-start",
				maxWidth: "1000px"
			}
		},
		"& .topRightWrapper": {
			margin: "56px -24px",
			"& .footermap": {
				display: "block",
				width: "100%"
			},
			[theme.breakpoints.up("lg")]: {
				margin: 0
			}
		},
		[theme.breakpoints.up("lg")]: {
			display: "flex",
			justifyContent: "space-between",
			marginBottom: 63
		}
	},

	/* Bottom */

	bottom: {
		"& .bottomLeftWrapper": {
			marginBottom: 24,
			"& .micadoWrapper": {
				"& .micado": {
					display: "block"
				}		
			},
			[theme.breakpoints.up("lg")]: {
				marginBottom: 0
			}
		},
		"& .bottomRightWrapper": {
			"& p": {
				fontSize: 12
			},
			[theme.breakpoints.up("lg")]: {
				display: "flex"
			}
		},
		[theme.breakpoints.up("lg")]: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center"
		}
	}
}));

export default styles;
