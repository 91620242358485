import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	megamenu: {
		display: "flex",
		flexDirection: "column",
		inset: 0,
		overflow: "hidden",
		position: "fixed",
		width: "100%",
		zIndex: theme.zIndex.drawer
	},
	container: {
		background: "#19646F",
		display: "flex",
		flexDirection: "column",
		right: "100% !important",
		transition: "right .3s ease",
		height: "100%",
		overflow: "auto",
		padding: "130px 40px 210px",
		position: "fixed",
		top: 0,
		width: "100%",
		zIndex: 1200,
		"& a:hover": {
			opacity: 0.8,
			textDecoration: "none"
		},
		"& .mco-view-component-desktopmenu__level1 li + li": {
			marginTop: 17
		},
		"& .mco-view-component-desktopmenu__level1 li:not(:last-child)": {
			marginBottom: 10
		},
		"& .mco-view-component-desktopmenu__level2 li + li": {
			marginTop: 12
		},
		[theme.breakpoints.up("lg")]: {
			alignItems: "flex-start",
			display: "flex",
			justifyContent: "center",
			paddingTop: 110,
			paddingLeft: 130,
			paddingBottom: 150,
			"& .mco-view-component-desktopmenu__level1 li + li": {
				marginTop: 26
			},
			"& .mco-view-component-desktopmenu__level2 li + li": {
				marginTop: 5
			}
		}
	},
	desktopmenu: {
		position: "relative"
	},
	submenuActive: {
		"& .mco-view-component-desktopmenu__link": {
			opacity: 0.5
		},
		"& .mco-view-component-desktopmenu__link--selected": {
			opacity: 1
		}
	},
	column1: {

	},
	column2: {
		[theme.breakpoints.up("lg")]: {
			left: "100%",
			margin: "0 0 0 56px",
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)"
		}
	},
	bottom: {

	}
}));

export default styles;
