import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	MenuList: {
		[theme.breakpoints.up("lg")]: {
			display: "flex",
			flexWrap: "wrap"
		}
	}
}));

export default styles;
