import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import Level1 from "./Level1";
import Level2 from "./Level2";
import MenuHeader from "./MenuHeader";
import Contact from "./Contact";

import styles from "./DesktopMenu.styles";

const DesktopMenu = ({ menuGroup, menuOpen, setMenuOpen }) => {
	const css = styles();
	const container = useRef(null);
	const [selectedMenuLevel1ID, setSelectedMenuLevel1ID] = useState();
	const [selectedMenuLevel2ID, setSelectedMenuLevel2ID] = useState();
	const [submenuIsActive, setSubmenuIsActive] = useState();
	const menuLevel1Ref = useRef();
	const menuLevel2Ref = useRef();

	useEffect(() => {
		disableBodyScroll(container.current);

		return () => {
			if (menuOpen) {
				clearAllBodyScrollLocks();
			}
		};
	}, [menuOpen]);

	const handleLevel1Click = level1ID => {
		setSubmenuIsActive(true);
		if (level1ID === selectedMenuLevel1ID) {
			setSelectedMenuLevel1ID(null);
			setSelectedMenuLevel2ID(null);
			return;
		}

		setSelectedMenuLevel1ID(level1ID);
		setSelectedMenuLevel2ID(null);
	};

	const handleLevel2Click = level2ID => {
		if (level2ID === selectedMenuLevel2ID) {
			setSelectedMenuLevel2ID(null);
			return;
		}

		setSelectedMenuLevel2ID(level2ID);
	};

	useEffect(() => {
		const selectedMainPoint = menuGroup?.items?.find(item => item.selected);
		setSelectedMenuLevel1ID(selectedMainPoint?.id);
		setSelectedMenuLevel2ID(selectedMainPoint?.items?.find(item => item.selected)?.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={clsx(css.container, "mco-view-component-megamenu__container")}
			ref={container}
		>
			<MenuHeader menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			<div className={clsx(css.desktopmenu, "mco-view-component-desktopmenu")} maxWidth="lg">
				<div
					className={clsx(css.column1, "mco-view-component-desktopmenu__column1", {
						[css.submenuActive]: submenuIsActive
					})}
					ref={menuLevel1Ref}
				>
					<Level1
						handleLevel1Click={handleLevel1Click}
						items={menuGroup?.items}
						menuLevel2Ref={menuLevel2Ref}
						selectedMenuLevel1ID={selectedMenuLevel1ID}
					/>
				</div>
				<div
					className={clsx(css.column2, "mco-view-component-desktopmenu__column2")}
					ref={menuLevel2Ref}
				>
					<Level2
						handleLevel2Click={handleLevel2Click}
						items={
							menuGroup?.items?.find(menuItem => menuItem.id === selectedMenuLevel1ID)?.items
						}
						selectedMenuLevel1ID={selectedMenuLevel1ID}
						selectedMenuLevel2ID={selectedMenuLevel2ID}
					/>
				</div>
			</div>
			<Contact />
		</div>
	);
};

export default DesktopMenu;
