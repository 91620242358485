import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	header: {
		background: "#FFFFFF",
		position: "fixed",
		fontFamily: "Dosis",
		letterSpacing: "2px",
		color: "#258D9D",
		textTransform: "uppercase",
		fontWeight: 700,
		left: 0,
		right: 0,
		top: 0,
		zIndex: 100,
		display: "flex",
		alignItems: "center",
		height: 90,
		paddingLeft: 24,
		paddingRight: 14,
		[theme.breakpoints.up("lg")]: {
			paddingLeft: 60,
			paddingRight: 60,
			height: 120,
			"& .mco-view-component-language": {
				color: "#258D9D"
			},
			"& .mco-view-component-button": {
				background: "#258D9D",
				boxShadow: "none",
				color: "#FFFFFF",
				paddingLeft: 24,
				paddingRight: 24,
				marginLeft: 60,
				marginRight: 60,
				transition: "opacity 300ms ease",
				whiteSpace: "nowrap"
			},
			"& .mco-view-component-button:hover": {
				opacity: 0.8
			},
			"& .mco-view-component-secondary-button": {
				border: "2px solid #258D9D",
				boxShadow: "none",
				color: "#258D9D",
				paddingLeft: 24,
				paddingRight: 24,
				marginLeft: -30,
				marginRight: 60,
				transition: "opacity 300ms ease",
				whiteSpace: "nowrap"
			},
			"& .mco-view-component-secondary-button:hover": {
				opacity: 0.8
			},
			"& .mco-view-component-language:after": {
				background: "#CCCCCC"
			}
		},
		"@media (min-width: 1280px) and (max-width: 1420px)": {
			"& .mco-view-component-button": {
				marginLeft: 20
			},
			"& .mco-view-component-secondary-button": {
				marginLeft: -50,
				marginRight: 20
			}
		}
	},
	logoWrapper: {
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.up("lg")]: {

		}
	},
	logo: {
		marginRight: 32,
		[theme.breakpoints.up("lg")]: {
			width: 360
		}
	},
	links: {
		display: "none",
		[theme.breakpoints.up("lg")]: {
			display: "flex",
			alignItems: "center",
			marginLeft: "auto"
		}
	},
	phone: {
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
		"& img": {
			marginRight: 12
		}
	},
	desktopMenuTrigger: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		"& p": {
			marginRight: 22
		}
	}
}));

export default styles;
